import React from "react";
import { Card, ContentCard, MetaPost } from "./styledCardPost";

export const CardPost = ({ post, noExcerpt, categorySelect, isMateraSlug }) => {
  const { slug, title, coverImage, category, excerpt, linkReports } = post
    ? post
    : [];

  const findIndex = category?.findIndex((props) => {
    return props === categorySelect;
  });

  const categoryRender = category[findIndex]
    ? category[findIndex]
    : category[0];

  const reports = category[0] === "reports e estudos";

  const checkSlugMatera = () => {
    if (isMateraSlug) {
      return `https://www.matera.com/br/blog/${slug}`;
    }
    return `/conteudos/${slug}`;
  };

  const postSlug = checkSlugMatera();

  return (
    <>
      {noExcerpt ? (
        <Card key={slug} noExcerpt>
          <a href={postSlug}>
            <img src={coverImage?.url} alt="alt" />
          </a>

          <ContentCard>
            <a href={postSlug} title={title}>
              {title}
            </a>
          </ContentCard>
        </Card>
      ) : (
        <Card key={slug}>
          {reports ? (
            <a href={linkReports}>
              <img src={coverImage?.url} alt="alt" />
            </a>
          ) : (
            <a href={postSlug}>
              <img src={coverImage?.url} alt="alt" />
            </a>
          )}

          <MetaPost>
            <span>{categoryRender}</span>
          </MetaPost>
          <ContentCard>
            {reports ? (
              <a href={linkReports} title={title}>
                {title}
              </a>
            ) : (
              <a href={postSlug} title={title}>
                {title}
              </a>
            )}

            {excerpt && <p title={excerpt}>{excerpt}</p>}
          </ContentCard>
        </Card>
      )}
    </>
  );
};
